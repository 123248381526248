import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/database";

const firebaseConfig: any = {
  apiKey: "AIzaSyAPJ8yWUVgvcgAqNpKBX9U4KgPZBzYN5hY",
  authDomain: "datamining-1184.firebaseapp.com",
  databaseURL:
    "https://datamining-1184-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "datamining-1184",
  storageBucket: "datamining-1184.appspot.com",
  messagingSenderId: "162542643484",
  appId: "1:162542643484:web:a417e457287d4c23c632d9",
};

const app = firebase.initializeApp(firebaseConfig);
export const auth = app.auth();
auth.setPersistence('session')
export default app
